exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-acceptable-use-policy-js": () => import("./../../../src/pages/acceptable-use-policy.js" /* webpackChunkName: "component---src-pages-acceptable-use-policy-js" */),
  "component---src-pages-affiliate-program-js": () => import("./../../../src/pages/affiliate-program.js" /* webpackChunkName: "component---src-pages-affiliate-program-js" */),
  "component---src-pages-business-cloud-hosting-js": () => import("./../../../src/pages/business-cloud-hosting.js" /* webpackChunkName: "component---src-pages-business-cloud-hosting-js" */),
  "component---src-pages-cloud-service-js": () => import("./../../../src/pages/cloud-service.js" /* webpackChunkName: "component---src-pages-cloud-service-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-containerized-vps-js": () => import("./../../../src/pages/containerized-vps.js" /* webpackChunkName: "component---src-pages-containerized-vps-js" */),
  "component---src-pages-dedicated-box-server-js": () => import("./../../../src/pages/dedicated-box-server.js" /* webpackChunkName: "component---src-pages-dedicated-box-server-js" */),
  "component---src-pages-dedicated-server-js": () => import("./../../../src/pages/dedicated-server.js" /* webpackChunkName: "component---src-pages-dedicated-server-js" */),
  "component---src-pages-domain-name-js": () => import("./../../../src/pages/domain-name.js" /* webpackChunkName: "component---src-pages-domain-name-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kvm-managed-server-js": () => import("./../../../src/pages/kvm-managed-server.js" /* webpackChunkName: "component---src-pages-kvm-managed-server-js" */),
  "component---src-pages-managed-dedicated-server-js": () => import("./../../../src/pages/managed-dedicated-server.js" /* webpackChunkName: "component---src-pages-managed-dedicated-server-js" */),
  "component---src-pages-managed-vps-js": () => import("./../../../src/pages/managed-vps.js" /* webpackChunkName: "component---src-pages-managed-vps-js" */),
  "component---src-pages-network-details-js": () => import("./../../../src/pages/network-details.js" /* webpackChunkName: "component---src-pages-network-details-js" */),
  "component---src-pages-node-js-hosting-js": () => import("./../../../src/pages/node-js-hosting.js" /* webpackChunkName: "component---src-pages-node-js-hosting-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-referral-program-js": () => import("./../../../src/pages/referral-program.js" /* webpackChunkName: "component---src-pages-referral-program-js" */),
  "component---src-pages-refund-policy-js": () => import("./../../../src/pages/refund-policy.js" /* webpackChunkName: "component---src-pages-refund-policy-js" */),
  "component---src-pages-remote-workspaces-js": () => import("./../../../src/pages/remote-workspaces.js" /* webpackChunkName: "component---src-pages-remote-workspaces-js" */),
  "component---src-pages-reseller-hosting-js": () => import("./../../../src/pages/reseller-hosting.js" /* webpackChunkName: "component---src-pages-reseller-hosting-js" */),
  "component---src-pages-reseller-program-js": () => import("./../../../src/pages/reseller-program.js" /* webpackChunkName: "component---src-pages-reseller-program-js" */),
  "component---src-pages-root-reseller-hosting-js": () => import("./../../../src/pages/root-reseller-hosting.js" /* webpackChunkName: "component---src-pages-root-reseller-hosting-js" */),
  "component---src-pages-service-level-agreement-js": () => import("./../../../src/pages/service-level-agreement.js" /* webpackChunkName: "component---src-pages-service-level-agreement-js" */),
  "component---src-pages-ssl-certificate-js": () => import("./../../../src/pages/ssl-certificate.js" /* webpackChunkName: "component---src-pages-ssl-certificate-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-web-hosting-js": () => import("./../../../src/pages/web-hosting.js" /* webpackChunkName: "component---src-pages-web-hosting-js" */),
  "component---src-pages-wordpress-hosting-js": () => import("./../../../src/pages/wordpress-hosting.js" /* webpackChunkName: "component---src-pages-wordpress-hosting-js" */)
}

